/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, leftColumn }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            link
            name
            private
          }
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        menuLinks={data.site.siteMetadata.menuLinks}
      />
      <div style={{ backgroundColor: "white" }}>
        <div style={styles.content}>
          {leftColumn && <div style={styles.leftColumn}>{leftColumn}</div>}
          <main style={{ flex: 1 }}>{children}</main>
        </div>
      </div>
      <footer>
        <div style={styles.footer}>
          <h6>© {new Date().getFullYear()} Global Medicines Council</h6>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const styles = {
  content: {
    margin: `0 auto`,
    maxWidth: 960,
    padding: `3rem 1.0875rem 1.45rem`,
    display: "flex",
  },
  leftColumn: {
    width: "250px",
    backgroundColor: "rgba(0,0,0,0.03)",
    marginRight: "1.45rem",
    padding: "1rem 1rem 0 1rem",
    fontSize: "90%",
  },
  footer: {
    textAlign: "center",
    padding: `2.5rem 0 1rem`,
  },
}

export default Layout
